import React from 'react';
import './Home.css'


class Home extends React.Component {

    componentDidMount() {
        this.thetaDropConnect();
    }

    thetaDropConnect = async (e) => {
        window.location.replace('./thetaDropConnect')
    }

    walletConnect = async (e) => {
        window.location.replace('./walletConnect')
    }

    render() {

        return (
            <div className='Home-Main'>
                <div className='home-image-container'>
                    <img src='./images/pinball-peril-logo-zotz.png'/>
                </div>
                <h3>NFT Verification</h3>
                <div className='home-button-container'>
                    <button onClick={(e)=>this.thetaDropConnect(e)}>Theta Drop</button>
                    {/* <br/>
                    <button onClick={(e)=>this.walletConnect(e)}>Browser Extension / Wallet</button> */}
                </div>
            </div>
        )
    }
}

export default Home