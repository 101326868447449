import './App.css';
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThetaPass } from '@thetalabs/theta-pass';
import Home from "./Home";
import ThetaDrop from './ThetaDropConnect';
import WalletConnect from './WalletConnect';

class App extends React.Component {

  // constructor(props) {
  //     // super(props)
  //     // this.thetaDrop = new ThetaDropConnect();

  //     // this.state = {
  //     //     isOwner: false
  //     // }
  // }


  render() {
      return (
          <Router>
              <div className="App">
                  <Routes>
                      <Route exact path="/" element={<Home/>} />
                      <Route exact path="/thetaDropConnect" element={<ThetaDrop/>} />
                      <Route exact path="/walletConnect" element={<WalletConnect/>} />
                      <Route exact path="/*" element={<Home/>} />
                  </Routes>
              </div>
          </Router>
      )
  }
}

export default App;