import React from 'react'
import "./ThetaDropConnect.css";
import { ThetaDropConnect } from '@thetalabs/theta-drop-connect';
import axios from 'axios';


const infoID = 'dapp_2di4d9mfc05s3q53jbga62mim67';
const redirectURLNew = 'https://video.pinballperil.cykoko.com/thetaDropConnect'

const apiUrl = "https://70k5j4bm12.execute-api.us-east-1.amazonaws.com/Prod/nftVerification"
//http://localhost:3000/thetaDropConnect?sns_id=user_b12yxgihkeupv26zz7sifvy4fb4&oauth2_token=80c9t0x6380s58wa86sjdz6pg70x1maz&state=undefined
let videoURL;

class ThetaDrop extends React.Component {

  constructor(props) {
    super(props)
    this.thetaDrop = new ThetaDropConnect();
    this.videoRef = React.createRef();



    this.state = {
      videoPlayerState: 0 //no video player //1 Show Video Player
    }
  }


  componentDidMount() {
    this.finishConnectViaRedirect();
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

    if(this.state.videoPlayerState === 1) {
      this.attemptAutoplay();
    } 


  }

  finishConnectViaRedirect = async () => {
    const result = await this.thetaDrop.finishConnectViaRedirect();
    if (result) {
      const { snsId, oauth2Token } = result;

      this.setState({
        videoPlayerState: 1,
        tpsId: snsId,
        authToken: oauth2Token
      });

      this.NFTverification(snsId);

    }
  }


  connectToThetaDrop = async (e) => {
    e.preventDefault();
    const { snsId, oauth2Token } = await this.thetaDrop.connectViaPopup(infoID, redirectURLNew);

    this.setState({
      videoPlayerState: 1,
      tpsId: snsId,
      authToken: oauth2Token
    });

    this.refreshUser();
    this.refreshOwnershipChecks();
  };

  connectToThetaDropViaRedirect = async () => {
    this.thetaDrop.connectViaRedirect(infoID, redirectURLNew);
  };


  NFTverification = async (snsId) => {
    const apiData = {
      "api": "x?p>Pn3h",
      "userId": snsId,
      "timeStamp": Date.now()
    }

    const apiResult = await axios.post(apiUrl, apiData);
    console.log(apiResult.data)
    console.log(Date.now())
    this.setState({ videoURL: apiResult.data.videoURL })
    if (apiResult.data.videoURL !== undefined) {
      videoURL = apiResult.data.videoURL
    }
  }

  attemptAutoplay = () => {
    const video = this.videoRef.current;
    if (video) {
      video.muted = true;  // Ensure the video is muted for autoplay to work
      video.play().catch(error => {
        console.error("Autoplay failed:", error);
        // Optionally, handle the error, e.g., show a play button to the user
      });
    }
  };

  handlePlayPause = (e) => {
    e.preventDefault();
    const video = this.videoRef.current;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  handleContextMenu = (e) => {
    e.preventDefault();
  };



  render() {

    return (
      <div className='Theta-Drop-Main'>
        {
          this.state.videoPlayerState === 0 &&
          <div>
            <div className='Theta-Drop-image-container'>
              <img src='./images/pinball-peril-logo-zotz.png' alt='main-logo'/>
            </div>
            <h3>ThetaDrop Connect</h3>
          </div>
        }

        <div className='Theta-Drop-button-container'>
          {
            this.state.videoPlayerState === 0 &&
            <div>
              <button onClick={() => this.connectToThetaDropViaRedirect()}>Connect</button>
              <br />
            </div>
          }

          {
            this.state.videoPlayerState === 1 &&
            <div className="video-container" >
              <video 
                ref={this.videoRef}
                src={videoURL}
                onContextMenu={this.handleContextMenu}
                className="video-player"
                onClick={e => this.handlePlayPause(e)}
                autoPlay
              >
                Your browser does not support the video tag.
              </video>
              <br />
              <p>Click To Play</p>
            </div>
          }

          <button onClick={() => { window.location.replace('./') }}>Back</button>
        </div>
      </div>
    )
  }
}

export default ThetaDrop