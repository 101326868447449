import React from 'react'
import { ethers } from "ethers";
import axios from 'axios';
import "./WalletConnect.css";

const apiURL = "https://70k5j4bm12.execute-api.us-east-1.amazonaws.com/Prod/nftVerification"
let videoURL;

class WalletConnect extends React.Component {

  constructor(props) {
    super(props)


    //0 Web3 not connected, 
    //1 No web3 found 
    //2 Wallet Connected - message pending 
    //3 Wallet Connected Message Denied 
    //4 Wallet Connected Message Signed - No NFT 
    //5 Wallet Connected Message Signed - 
    //6? Too many NFTs
    //7? Wrong NFT?

    this.state = {
      loadingState: 0, 
      address: null,
      videoPlayerState : 0
    }
  }


  componentDidMount() {

  }

  checkWeb3 = async (e) => {
    e.preventDefault();
    let signer = null;

    let provider;
    if (window.ethereum == null) {
      alert("Web3 Extension not found.")
      provider = ethers.getDefaultProvider()
      this.setState({loadingState : 1})
    } else {
      try {
        provider = new ethers.BrowserProvider(window.ethereum)
        signer = await provider.getSigner();
        const network = await provider.getNetwork();

        //Check Chain Id
        if(Number(network.chainId) !== 361){
          this.switchNetwork(signer); 
        } else {
          this.signMessage(signer);
          this.setState({ address: signer.address })
        }
      } catch {
      alert("Error with Web3 Extension")
    }

    }
  }

  switchNetwork = async (signer) => {
    if (!window.ethereum) {
      console.error('No Ethereum browser extension detected');
      return;
    }

    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [{
            chainId: "0x169",
            rpcUrls: ["https://eth-rpc-api.thetatoken.org/rpc"],
            chainName: "Theta Mainnet",
            nativeCurrency: {
                name: "TFUEL",
                symbol: "TFUEL",
                decimals: 18
            }
        }]
    });



   
   //Checking to see if user actually switched the network
    let provider = new ethers.BrowserProvider(window.ethereum)
    const network = await provider.getNetwork();
    if(Number(network.chainId) === 361){ this.signMessage(signer);}
    else {alert("Trouble with switching networks..Please try again.")}

    } catch (error) {
      if (error.code === 4902) {
        // This error code indicates that the chain has not been added to MetaMask.
        console.error('This network is not available in your MetaMask, please add it manually.');
      } else {
        console.error('Failed to switch network:', error);
      }
    }
  };


  signMessage = async (signer) => {
    let message = {
      "Contract Address" : "0x5d9aaa9bd3fb7efe08937ace4f34f3d1ca94f0d3",
      "TimeStamp" : Date.now(),
      "Wallet" : signer.address
    }

    console.log(JSON.stringify(message))

    try{
      let rawSig = await signer.signMessage(JSON.stringify(message));
      console.log(rawSig);
      console.log("Raw", JSON.stringify(message));
      //send request out to API
      this.sendMessageAndSignatureToAPI(rawSig, message, signer.address)
    }catch (e){
      alert("Error with signing message")
      console.log(e)
    }
    
    

  }

  sendMessageAndSignatureToAPI = async (signature, message, wallet) => {
    const apiData = JSON.stringify({
      "api" : "es7omqwS", 
      "userId" : wallet,
      "signature" : signature,
      "message" : message,
      "timeStamp" : Date.now()
    })
    
    const apiResult = await axios.post(apiURL, apiData);

    console.log(apiResult.data)
    console.log(apiData)
    this.setState({ videoPlayerState: 1, videoURL: apiResult.data.videoURL })
    if (apiResult.data.videoURL !== undefined) {
      videoURL = apiResult.data.videoURL
    }
  }



  render() {

    return (
      <div className='Wallet-Connect-Main'>
        {
          this.state.videoPlayerState === 0 &&
          <div>
            <div className='Theta-Drop-image-container'>
              <img src='./images/pinball-peril-logo-zotz.png' alt='main-logo'/>
            </div>
            <h3>Wallet Connect</h3>
          </div>
        }

        <div className='Theta-Drop-button-container'>
          {
            this.state.videoPlayerState === 0 &&
            <div>
              <button onClick={(e) => this.checkWeb3(e)}>Connect</button>
              <br />
            </div>
          }

          {
            this.state.videoPlayerState === 1 &&
            <div className="video-container" >
              <video 
                ref={this.videoRef}
                src={videoURL}
                onContextMenu={this.handleContextMenu}
                className="video-player"
                onClick={e => this.handlePlayPause(e)}
                autoPlay
              >
                Your browser does not support the video tag.
              </video>
              <br />
              <p>Click To Play</p>
            </div>
          }
          <button onClick={() => { window.location.replace('./') }}>Back</button>
        </div>

      </div>
    )
  }
}

export default WalletConnect



  // checkThetaAPIForNFTs = async (address) => {
  //   // console.log(address);
  //   // let contractAddress = "0x7aa1c55b23ec5776b8f0fa9420fe7d3c8a362da7";
  //   // //https://player.thetavideoapi.com/video/video_tc7xi1stifqjn6urd07511727x
  //   // let apiLink = `http://www.thetascan.io/api/721/?address=${address}&contract=${contractAddress}`;
  //   // console.log(apiLink)
  //   // const response = await axios.get(apiLink);
    
  //   //ideally send out request 
  // }